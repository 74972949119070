import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

// Components
import BalancingCard from './BalancingCard';
import Link from './Link';

export interface PhotosCardProps {
  year: number;
  photos: IGatsbyImageData[];
  color: string;
}

export default class PhotosCard extends React.Component<PhotosCardProps> {
  render(): React.ReactNode {
    return (
      <Link
        to={`/${this.props.year}/fotos`}
        className="flex flex-row justify-center w-full h-48 hover:opacity-80 active:opacity-50 transition-opacity"
      >
        <div
          className="rounded-xl flex flex-row gap-8 overflow-hidden w-full h-full relative corner-fix"
          style={{
            background: this.props.color,
          }}
        >
          <div className="absolute top-0 left-0 h-full w-full z-10">
            <div className="h-full grid grid-cols-8 grid-rows-3 max-w-lg rounded-xl overflow-hidden">
              {this.props.photos.map((node, i) => {
                const photo = getImage(node);
                return (
                  photo && (
                    <GatsbyImage
                      key={i}
                      image={photo}
                      alt="Foto van Justitia"
                      imgClassName="aspect-square"
                    />
                  )
                );
              })}
            </div>
            <div
              className="absolute top-0 left-0 w-full h-full rounded-xl"
              style={{
                background: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${this.props.color}F2 70%, ${this.props.color} 100%)`,
              }}
            />
          </div>
          <p className="absolute z-20 top-0 right-0 text-white text-4xl font-black p-8 max-w-sm text-right">
            Bekijk de foto's van Justitia {this.props.year}
          </p>
        </div>
      </Link>
    );
  }
}
