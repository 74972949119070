import { GatsbyImage } from "gatsby-plugin-image";
import React, { Component } from "react";
import Link from "./Link";

export interface ManagementCardProps {
  year: number;
  color: string;
  people: {
    name: {
      full: string;
    };
    picture: any;
  }[];
}

export class ManagementCard extends Component<ManagementCardProps> {
  render() {
    return (
      <Link to={`/${this.props.year}/bestuur`}>
        <div
          className="relative h-full w-full rounded-xl hover:opacity-80 active:opacity-50 transition-opacity cursor-pointer overflow-hidden corner-fix"
          style={{
            background: this.props.color,
          }}
        >
          <div className="relative z-20 text-white p-6">
            <h3 className="text-3xl font-black">
              Het bestuur van {this.props.year}
            </h3>
            <p className="text-sm">
              Bekijk het bestuur van Justitia {this.props.year}
            </p>
          </div>
          {/* linear gradient behind text */}
          <div
            className="absolute top-0 left-0 w-full h-full z-10"
            style={{
              background: `linear-gradient(90deg, ${this.props.color} 0%, ${this.props.color}00 100%)`,
            }}
          />

          <div className="absolute top-0 right-0 h-full flex flex-row opacity-20">
            {this.props.people
              .filter(
                (person) =>
                  person.picture?.childImageSharp?.gatsbyImageData !== null
              )
              .map((person: any) => (
                <GatsbyImage
                  key={person.name.full}
                  image={person.picture?.childImageSharp.gatsbyImageData}
                  alt={person.name.full}
                  imgClassName="w-full h-full object-cover"
                />
              ))}
          </div>
        </div>
      </Link>
    );
  }
}
