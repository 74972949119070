import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Link from "./Link";

const sponsorsQuery = graphql`
  query SponsorQuery {
    allCompanies(filter: { sponsor: { eq: true } }) {
      nodes {
        identifier
        name
        website
        logo {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              height: 48
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    currentYear {
      year
    }
  }
`;

export default class SponsorTicker extends React.Component {
  render() {
    return (
      <StaticQuery
        query={sponsorsQuery}
        render={(data) => (
          <Link to="/sponsoren">
            <div className="relative w-full overflow-x-hidden h-12">
              <div className="h-12 absolute top-0 left-0 flex flex-row gap-8 animate-marquee px-6">
                {data.allCompanies.nodes.map((node: any) => {
                  return (
                    <GatsbyImage
                      image={node.logo.childImageSharp.gatsbyImageData}
                      alt={"Logo van " + node.name}
                      draggable={false}
                      objectFit="scale-down"
                      loading="eager"
                      key={node.identifier + "1"}
                      style={{
                        maxWidth: "150px",
                      }}
                    />
                  );
                })}
              </div>
              <div className="h-12 absolute top-0 left-0 flex flex-row gap-8 animate-marquee2 px-6">
                {data.allCompanies.nodes.map((node: any) => {
                  return (
                    <GatsbyImage
                      image={node.logo.childImageSharp.gatsbyImageData}
                      alt={"Logo van " + node.name}
                      draggable={false}
                      objectFit="scale-down"
                      loading="eager"
                      key={node.identifier + "2"}
                      style={{
                        maxWidth: "150px",
                      }}
                    />
                  );
                })}
              </div>
              <div className="opacity-0 md:opacity-100 absolute top-0 left-0 w-full h-full from-10% to-90% bg-gradient-to-r from-white via-transparent to-white" />
            </div>
          </Link>
        )}
      />
    );
  }
}
